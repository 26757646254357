import { debugLog } from './debug';
import { getParentWithClass, LOADED_SERVER_RENDERED_IFRAME_SELECTOR, SERVER_RENDERED_IFRAME_SELECTOR, VIDEO_EMBED_SRC_PATTERN } from './dom';
import { getEmbedIframeDomain } from './env';
import { reportMessage } from './errors';
import { findPlaceholderImages, parsePlaceholderAttrs } from './placeholders';
export function setupFormSubmittedListener() {
  const handleFormSubmitted = ({
    id
  }) => {
    const inlineMessageEl = document.querySelector(`.hs-form-${id}.submitted-message`);
    if (inlineMessageEl) {
      const placeholders = findPlaceholderImages(inlineMessageEl);
      debugLog(`Found ${placeholders.length} players in form thank you message`);
      placeholders.forEach(({
        el,
        options
      }) => {
        options.renderContext = 'form-inline-message';
        window.hsVideoApi.renderPlayer(el, options);
      });
    }
  };
  window.addEventListener('message', event => {
    if (event.data && event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
      debugLog('Form submitted', event.data);
      setTimeout(() => handleFormSubmitted(event.data));
    }
  });
}
export function setupMutationObserver() {
  if (!window.MutationObserver) {
    return null;
  }
  const observer = new MutationObserver(mutationList => {
    mutationList.forEach(record => {
      if (!record.addedNodes.length) {
        return;
      }
      const iframeEls = [...record.addedNodes].filter(node => node instanceof Element && (node.matches(SERVER_RENDERED_IFRAME_SELECTOR) || node.matches(LOADED_SERVER_RENDERED_IFRAME_SELECTOR)));
      record.addedNodes.forEach(node => {
        if (!(node instanceof Element)) {
          return;
        }
        iframeEls.push(...node.querySelectorAll(SERVER_RENDERED_IFRAME_SELECTOR), ...node.querySelectorAll(LOADED_SERVER_RENDERED_IFRAME_SELECTOR));
      });
      if (!iframeEls.length) {
        return;
      }
      debugLog('Found server-rendered iframes via MutationObserver:', iframeEls);
      reportMessage('Found server-rendered iframes via MutationObserver (leaving here for debugging)');
      iframeEls.forEach(iframeEl => {
        const wrapperEl = getParentWithClass(iframeEl, '.hs-video-widget[data-hsv-embed-id]');
        if (!wrapperEl || !wrapperEl.dataset.hsvEmbedId) {
          return;
        }
        // detect any already loaded iframes that already had an EmbedPlayer
        const player = window.hsVideoApi.getPlayer(wrapperEl.dataset.hsvEmbedId);
        if (player) {
          // replace the EmbedPlayer's iframe reference and repeat setup steps so it receives postMessages necessary for tracking
          debugLog(`Moving player ${wrapperEl.dataset.hsvEmbedId} to new iframe: `, iframeEl);
          player.replaceIframe(iframeEl);
        } else {
          // boot up any placeholder (not yet loaded) iframes found in DOM we haven't seen yet
          const options = parsePlaceholderAttrs(wrapperEl);
          if (options !== null && options !== void 0 && options.embedId) {
            options.renderContext = 'hubl-iframe';
            window.hsVideoApi.renderPlayer(wrapperEl, options);
          }
        }
      });
    });
  });
  try {
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  } catch (err) {
    reportMessage('Failed to create MutationObserver');
  }
  return observer;
}
export function setupCspViolationListener() {
  document.addEventListener('securitypolicyviolation', e => {
    debugLog('SecurityPolicyViolationEvent event', e);
    const {
      blockedURI,
      disposition
    } = e;
    if (e.blockedURI && VIDEO_EMBED_SRC_PATTERN.test(blockedURI)) {
      reportMessage(`CSP ${disposition} blocked video-embed JS`, {
        blockedURI
      });
    }
    if (e.blockedURI && e.blockedURI.includes(getEmbedIframeDomain())) {
      reportMessage(`CSP ${disposition} blocked player iframe load`, {
        blockedURI
      });
    }
  });
}